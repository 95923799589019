import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { CursorLoadingService } from '@proman/services/cursor-loading.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { hasSubstring } from '@proman/utils';
import { ArticleOperationWorkplace } from '@proman/interfaces/entity-interfaces';

@Component({
    selector: 'pm-article-operation-workplace-select-dialog',
    template: `
        <pro-dialog-title title="select_workplaces"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-txt [config]="{ label: 'search', debounce: 0 }"
                    [value]="filterQuery"
                    (onChange)="setFilterQuery($event)"></pm-txt>
            <pro-checkbox [value]="selectAll"
                         [config]="{ label: 'select_all' }"
                         (onChange)="toggleAll($event)"></pro-checkbox>
            <div *ngFor="let workplace of filteredWorkpaces; trackBy: trackByFn">
                <pro-checkbox [value]="workplace.selected"
                             [config]="{ label: workplace._name }"
                             (onChange)="set(workplace, $event)"></pro-checkbox>

            </div>

        </div>
        <pro-dialog-actions variant="confirm"
                           (callback)="confirm()"></pro-dialog-actions>
    `
})

export class ArticleOperationWorkplaceSelectDialogComponent {
    workplaces: ArticleOperationWorkplace[];
    filteredWorkpaces: ArticleOperationWorkplace[];
    filterQuery: string = '';
    selectAll: boolean;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        Entity: Entity,
        public dialogRef: MatLegacyDialogRef<ArticleOperationWorkplaceSelectDialogComponent>,
        private cursorLoading: CursorLoadingService,
        private queryExpression: QueryExpressionService,
    ) {
        this.cursorLoading.start();
        Entity.get('article_operation_workplace')
            .search({
                'workplace.id': this.data.workplace.id,
                'articleOperation.article.name': this.queryExpression.notNull(),
                'join': ['articleOperation', 'articleOperation.operation', 'articleOperation.article']
            })
            .then((response: ArticleOperationWorkplace[]) => {
                response.forEach((item) => {
                    let operation = item.articleOperation;

                    item._name = operation.article.name + ' ' + operation.operation.name;
                });

                this.workplaces = response;
                this.filteredWorkpaces = response.map((item) => item);
                this.cursorLoading.stop();
            });
    }

    confirm() {
        let result = this.workplaces.filter((item: any) => item.selected);
        this.dialogRef.close(result);
    }

    toggleAll(value: boolean) {
        this.workplaces.forEach((item: any) => {
            item.selected = hasSubstring(item._name, this.filterQuery) ? value : false;
        });
    }

    set(item: any, value: any) {
        item.selected = value;
    }

    trackByFn(index: number, item: any) {
        return item.id;
    }

    setFilterQuery(value: string) {
        this.filterQuery = value;

        this.filteredWorkpaces = this.workplaces.filter((item) => this.filterQuery ? hasSubstring(item._name, this.filterQuery) : true);
    }

}
