import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';

@Component({
    selector: 'pm-workgroup-specialisaation-remove',
    template: `
        <pro-dialog-title title="remove_specialisations"></pro-dialog-title>
        <div mat-dialog-content>
            <h3>{{ 'remove ' | translate }} {{ data.specialisation.name }}</h3>
            <div *ngIf="workplaceEmployeeToRemove?.length">
                <p>{{ 'employees_that_will_be_removed_from_workplaces' | translate }}</p>
                <table class="Table">
                    <thead>
                    <tr>
                        <th>{{ 'employee' | translate }}</th>
                        <th>{{ 'workplace' | translate }}</th>
                        <th>{{ 'specialisation' | translate }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of workplaceEmployeeToRemove">
                        <td>{{ item.employee.name }}</td>
                        <td>{{ item.workplace.name }}</td>
                        <td>{{ item.specialisation.name }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <pro-dialog-actions (callback)="confirm()"
                           variant="confirm"></pro-dialog-actions>
    `
})

export class WorkgroupSpecialisationRemoveDialogComponent {
    workplaceEmployeeIds: any = [];
    workplaceEmployeeToRemove: any;
    workplaceEmployeeEntity: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Entity: Entity,
        public dialogRef: MatLegacyDialogRef<WorkgroupSpecialisationRemoveDialogComponent>
    ) {
        this.workplaceEmployeeEntity = Entity.get('workplace_employee');
        this.workplaceEmployeeEntity
            .search({
                'workplace.workgroup.id': this.data.workgroupId,
                'specialisation.id': this.data.specialisation.id,
                'join': ['workplace', 'workplace.workgroup', 'specialisation'],
                'partialJoin': {
                    employee: ['id', 'name'],
                },
            })
            .then((response: any) => {
                this.workplaceEmployeeToRemove = response;
                this.workplaceEmployeeIds = this.workplaceEmployeeToRemove.map((item: any) => item.id);
            });
    }

    confirm() {
        this.Entity.get('workgroup')
            .removeAssociation({ id: this.data.workgroupId, specialisations: this.data.specialisation.id })
            .then(() => {
                if (this.workplaceEmployeeIds.length) {
                    this.workplaceEmployeeEntity
                        .remove({ id: this.workplaceEmployeeIds })
                        .then((response: any) => this.dialogRef.close(response));

                } else {
                    this.dialogRef.close(1);

                }
            });
    };
}
