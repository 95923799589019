import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { Entity } from '@proman/services/entity.service';
import { Warehouse, WarehouseLocation } from '@proman/interfaces/entity-interfaces';
import { ModelService } from '@proman/services/model.service';
import { Dialog } from '@frontend/shared/services/dialog.service';
import { EntityCreateDialogComponent } from '@frontend/shared/components/entity-create-dialog.component';
import { BarcodePrinterPrintDialogComponent } from '../../printers/components/barcode-printer-print-dialog.component';

@Component({
    selector: 'pm-warehouse-edit-dialog',
    template: `
        <pro-dialog-title [title]="warehouse.name"></pro-dialog-title>
        <div mat-dialog-content fxLayout="column">
            <pm-txt [value]="warehouse.name"
                    [config]="{ label: 'name' }"
                    (onChange)="model.update('name', $event)"></pm-txt>
            <pm-txt [value]="warehouse.alias"
                    [config]="{ label: 'alias' }"
                    (onChange)="model.update('alias', $event)"></pm-txt>
            <pm-txt [value]="warehouse.address"
                    [config]="{ label: 'address' }"
                    (onChange)="model.update('address', $event)"></pm-txt>
            <pm-txt [value]="warehouse.description"
                    [config]="{ label: 'description' }"
                    (onChange)="model.update('description', $event)"></pm-txt>
            <pro-color [value]="warehouse.color"
                       (onChange)="model.update('color', $event)"></pro-color>
            <pm-txt [value]="warehouse.divider"
                    [config]="{ label: 'divider' }"
                    (onChange)="model.update('divider', $event)"></pm-txt>
            <pro-checkbox [value]="warehouse.saleStock"
                         [config]="{ label: 'sale_stock' }"
                         (onChange)="model.update('saleStock', $event)"></pro-checkbox>

            <pro-list-manager *ngIf="locations"
                             [items]="locations"
                             [config]="{ label: 'locations' }"
                             (onAdd)="handleAdd()"
                             (onEdit)="handleEdit($event)"
                             (onRemove)="handleRemove($event)"
                             (onBarcode)="handleBarcode($event)"
            ></pro-list-manager>
            <pm-corporate-panel [entity]="warehouse"
                                [class]="'warehouse'"
                                [config]="{ single: true }"
                                (onSingleChange)="model.update('namespace', $event.name)"
                                ></pm-corporate-panel>


        </div>
        <pro-dialog-actions></pro-dialog-actions>
    `
})

export class WarehouseEditDialogComponent implements OnInit {
    warehouse: Warehouse;
    model: any;
    locations: WarehouseLocation[];
    warehouseLocationEntity: any;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
        private Dialog: Dialog,
        private Entity: Entity,
        private Model: ModelService,
        private dialogRef: MatLegacyDialogRef<WarehouseEditDialogComponent>,
    ) {
        this.warehouse = this.data.item;

        this.model = this.Model.get(this.warehouse, 'warehouse');
        this.warehouseLocationEntity = this.Entity.get('warehouse_location');

    }

    ngOnInit() {
        this.getLocations();
    }

    getLocations() {
        this.warehouseLocationEntity
            .search({ 'warehouse.id': this.warehouse.id })
            .then((response: WarehouseLocation[]) => this.locations = response);
    }

    handleAdd() {
        this.Dialog
            .open(EntityCreateDialogComponent,
                {
                    entity: 'warehouse_location',
                    params: { 'warehouse.id': this.warehouse.id },
                    mainField: { notUnique: true }
                })
            .then(() => this.getLocations());
    }

    handleRemove(data: any) {
        this.warehouseLocationEntity
            .remove({ id: data.item.id })
            .then(() => this.getLocations());
    }

    handleEdit(data: any) {
        this.
            Dialog.entityEdit('warehouse_location', data.item)
            .afterClosed()
            .subscribe(() => this.getLocations());
    }

    handleBarcode(warehouseLocation: WarehouseLocation) {
        this.Dialog.open(BarcodePrinterPrintDialogComponent, { type: 'warehouseLocation', warehouseLocation }, { width: '325px' })
    }

    updateWarehouseNamespace = (property: string, value: any) => {
        this.Entity.get('warehouse')
            .update({
                id: this.warehouse.id,
                namespace: value
            })

    };
}
